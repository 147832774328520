import { init } from '@sentry/browser';

//*********Action Types ************************ */
export const UPDATE_COMPARE_PRODUCT = 'app/ListingPage/UPDATE_COMPARE_PRODUCT_REQUEST';

//*******************Reducer********************* */

const initialState = {
  compareIds: [],
};

const compareProduct = (state = initialState, action = {}) => {
  //   console.log('initialState', initialState);
  const { type, payload } = action;
  switch (type) {
    // case SET_INITIAL_VALUES:
    //   return { ...initialState, ...payload };
    case UPDATE_COMPARE_PRODUCT:
      return {
        ...state,
        compareIds: payload,
      };

    default:
      return state;
  }
};

export default compareProduct;

// ================ Action creators ================ //
// export const setInitialValues = () => ({
//   type: SET_INITIAL_VALUES,
// });

// export const setInitialValues = initialValues => ({
//   type: UPDATE_COMPARE_PRODUCT,
//   payload: pick(initialValues, Object.keys(initialState)),
// });

export const updateCompareProductRequest = productIds => ({
  type: UPDATE_COMPARE_PRODUCT,
  payload: productIds,
});

// ================ Thunks ================ //
